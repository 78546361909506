import React from 'react'
import tw from 'twin.macro'

const Digital = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.90012 23.3998C8.28827 23.3977 6.73853 22.7778 5.56981 21.6678C4.40109 20.5577 3.70228 19.042 3.61719 17.4324C3.5321 15.8228 4.06719 14.2418 5.11236 13.0147C6.15753 11.7876 7.63327 11.0078 9.23592 10.8358C8.76402 8.98518 9.0466 7.02291 10.0215 5.38065C10.9964 3.7384 12.5837 2.55068 14.4343 2.07878C16.2849 1.60688 18.2472 1.88945 19.8894 2.86434C21.5317 3.83923 22.7194 5.42658 23.1913 7.27718C24.2919 7.12393 25.4123 7.19878 26.4827 7.49707C27.5532 7.79537 28.5508 8.31072 29.4135 9.01108C30.2762 9.71144 30.9856 10.5818 31.4976 11.5681C32.0095 12.5544 32.313 13.6354 32.3893 14.744C32.4655 15.8526 32.3129 16.9651 31.9408 18.0121C31.5687 19.0592 30.9851 20.0185 30.2264 20.8304C29.4676 21.6423 28.55 22.2893 27.5304 22.7314C26.5109 23.1734 25.4113 23.4009 24.3001 23.3998H19.8001V16.9432L22.1275 19.2706C22.467 19.5985 22.9217 19.7799 23.3936 19.7758C23.8656 19.7717 24.3171 19.5824 24.6508 19.2487C24.9845 18.9149 25.1738 18.4635 25.1779 17.9915C25.182 17.5195 25.0006 17.0649 24.6727 16.7254L19.2727 11.3254C18.9352 10.9879 18.4774 10.7984 18.0001 10.7984C17.5228 10.7984 17.0651 10.9879 16.7275 11.3254L11.3275 16.7254C10.9996 17.0649 10.8182 17.5195 10.8223 17.9915C10.8264 18.4635 11.0157 18.9149 11.3494 19.2487C11.6832 19.5824 12.1346 19.7717 12.6066 19.7758C13.0786 19.7799 13.5332 19.5985 13.8727 19.2706L16.2001 16.945V23.3998H9.90012Z"
            fill="currentColor"
        />
        <path
            d="M16.2 23.3999H19.8V32.3999C19.8 32.8773 19.6103 33.3351 19.2727 33.6727C18.9352 34.0103 18.4773 34.1999 18 34.1999C17.5226 34.1999 17.0647 34.0103 16.7272 33.6727C16.3896 33.3351 16.2 32.8773 16.2 32.3999V23.3999Z"
            fill="currentColor"
        />
    </svg>
)

export default Digital
