import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import Benefits from '../../components/shared/landingPages/benefits'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Digital from '../../images/bonnen/digital.js'
import Features from '../../images/bonnen/features.js'
import Shield from '../../images/bonnen/shield.js'
import Tower from '../../images/bonnen/tower.js'
import Credit from '../../images/icons/credit.js'
import ExpensesLottie from '../../images/lottieAnimations/ExpensesAnimation.js'
import upToDate from '../../images/bonnen/upToDate.png'
import benefit from '../../images/bonnen/benefits.png'

const topHighlightData = {
    title: `Scan je bonnetjes met Tellow`,
    // subtitle: `Tellow geeft je een handig overzicht van betaalde en niet-betaalde facturen inclusief herinneringen.`,
    points: [
        {
            Icon: Digital,
            title: `Digitaal bewaard`,
            body: `
            Tellow bewaart je bonnen digitaal en op één plek. Hiermee voldoe je aan de eisen van de Belastingdienst.
        `,
        },
        {
            Icon: Features,
            title: `Altijd bij de hand`,
            body: `
            In het overzicht heb je alles bij de hand. Nooit meer rondslingerende bonnetjes.
        `,
        },
        {
            Icon: Shield,
            title: `Nooit een bon kwijt`,
            body: `
            Vind gemakkelijk een oude bon terug met de handige zoekfunctie.
        `,
        },
        {
            Icon: Tower,
            title: `Eenvoudige app`,
            body: `
            Met onze app scan eenvoudig je bonnetjes en facturen. De Tellow-app is er voor Android en iOS.
        `,
        },
    ],
}

const headerData = {
    title: `Eenvoudig je bonnen scannen
    en verwerken`,
    text: `Scan je bonnetjes en facturen met de Tellow-app op je telefoon. Gemakkelijk te gebruiken en direct toegevoegd aan je administratie voor de Belastingdienst. Alles wordt op één overzichtelijke plek bewaard zodat je nooit meer je bonnen hoeft te zoeken.`,
    animation: <ExpensesLottie />,
    textCTA: `Gebruik Tellow gratis`,
}

const highlightedFunctionalityData = {
    title: `Up-to-date`,
    subtitle: `Koppel je bankrekening aan Tellow en al je bonnetjes en facturen worden automatisch aan de juiste banktransacties gekoppeld. Hierdoor is je administratie altijd up-to-date.`,
    firstStepImage: upToDate,
}

const featureData = [
    {
        Icon: Credit,
        title: `Bankrekening koppelen`,
        text: `Koppel je bankrekening aan Tellow en maak je administratie nog makkelijker. Direct inzicht in al je uitgave en inkomsten.`,
        link: `Lees meer over het koppelen van je rekening`,
        path: `koppel-je-rekening`,
    },
    {
        Icon: Credit,
        title: `Facturen`,
        text: `Stuur onbeperkt facturen in je eigen huisstijl. Gebruik onze gedesignde templates voor een professionele look.`,
        link: `Lees meer over facturen maken`,
        path: `facturen`,
    },
]

const benefitsData = {
    points: [
        {
            title: 'Bespaar tijd',
            body: 'Scan je bon in één klik. Tellow neemt automatisch alle informatie over. Het overtypen van bonnen is verleden tijd.',
            links: `Scan jouw bonnen`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Systematisch',
            body: 'Je bonnen worden na het scannen automatisch onder de juiste kostenpost gezet. Hierdoor heb je al je uitgaven op een rijtje.',
            links: `Begin nu`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Overzichtelijk',
            body: 'Alle bonnen en facturen worden veilig opgeslagen en zijn overzichtelijk terug te vinden in je dashboard.',
            links: `Maak een account aan`,
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const Bonnen = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            keywords={['bonnen', 'zzp', 'tellow']}
            path="/functies/bonnen"
            title="Bonnen scannen | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <Header data={headerData} showAnimation displayImage={false} />

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} purpleBackground />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Bonnen
