import React from 'react'
import tw from 'twin.macro'

const Features = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.9999 3.6001C8.04512 3.6001 7.12945 3.97938 6.45432 4.65451C5.77919 5.32964 5.3999 6.24532 5.3999 7.2001V32.4001L11.6999 28.8001L17.9999 32.4001L24.2999 28.8001L30.5999 32.4001V7.2001C30.5999 6.24532 30.2206 5.32964 29.5455 4.65451C28.8704 3.97938 27.9547 3.6001 26.9999 3.6001H8.9999ZM13.4999 9.0001C12.7838 9.0001 12.0971 9.28456 11.5907 9.79091C11.0844 10.2973 10.7999 10.984 10.7999 11.7001C10.7999 12.4162 11.0844 13.1029 11.5907 13.6093C12.0971 14.1156 12.7838 14.4001 13.4999 14.4001C14.216 14.4001 14.9027 14.1156 15.4091 13.6093C15.9154 13.1029 16.1999 12.4162 16.1999 11.7001C16.1999 10.984 15.9154 10.2973 15.4091 9.79091C14.9027 9.28456 14.216 9.0001 13.4999 9.0001ZM24.6725 9.5275C24.335 9.19005 23.8772 9.00048 23.3999 9.00048C22.9226 9.00048 22.4649 9.19005 22.1273 9.5275L11.3273 20.3275C11.1554 20.4935 11.0183 20.6922 10.9239 20.9118C10.8296 21.1314 10.7799 21.3676 10.7779 21.6066C10.7758 21.8456 10.8213 22.0826 10.9118 22.3038C11.0023 22.525 11.136 22.726 11.305 22.895C11.474 23.064 11.675 23.1977 11.8962 23.2882C12.1174 23.3787 12.3544 23.4242 12.5934 23.4221C12.8324 23.4201 13.0686 23.3704 13.2882 23.2761C13.5078 23.1817 13.7065 23.0446 13.8725 22.8727L24.6725 12.0727C25.01 11.7351 25.1995 11.2774 25.1995 10.8001C25.1995 10.3228 25.01 9.86505 24.6725 9.5275ZM22.4999 18.0001C21.7838 18.0001 21.0971 18.2846 20.5907 18.7909C20.0844 19.2973 19.7999 19.984 19.7999 20.7001C19.7999 21.4162 20.0844 22.1029 20.5907 22.6093C21.0971 23.1156 21.7838 23.4001 22.4999 23.4001C23.216 23.4001 23.9027 23.1156 24.4091 22.6093C24.9154 22.1029 25.1999 21.4162 25.1999 20.7001C25.1999 19.984 24.9154 19.2973 24.4091 18.7909C23.9027 18.2846 23.216 18.0001 22.4999 18.0001Z"
            fill="currentColor"
        />
    </svg>
)

export default Features
