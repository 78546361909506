import React from 'react'
import Lottie from 'lottie-react'
import ExpensesAnimation from './expenses.json'
import { AnimationContainer } from './AnimationContainer.styles.js'

const LottieExpensesAnimation = () => (
    <AnimationContainer>
        <Lottie animationData={ExpensesAnimation} />
    </AnimationContainer>
)

export default LottieExpensesAnimation
